body, #root {
    height: 100%;
}

.login-bg {
    background-color: #3b3e47;
    padding-top: 100px;
    height: 100%;
}

.org-logo img {
    height: 60px;
    margin-bottom: 5px;
}
.app-name {
    font-size: 2em;
    font-weight: 600;
    color: #000;
    margin-top: 25px;
    margin-bottom: 30px;
}

.login-card {
    text-align: center;
    background-color: #ffffff;
    padding: 30px;
    border-radius: .5em;
}

.error-message {
    color: #ff0000;
}

.login-footer {
    margin-top: 30px;

}