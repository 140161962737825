.layout-main {
  @include transition(margin-left $transitionDuration);
  padding: 66px 16px 16px 16px;
}

.page-head {
  font-size: 1.6em;
  font-weight: 600;
  margin-bottom: 25px;
}
